import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import {
  updateValidationError,
  updateLocalHandlingDataField,
} from "../../../redux";
import { getValidationErrorMessage } from "../../../utils/validations/validations";
import YesNoSelection, {
  YesNoSelectionValues,
} from "../../common/yesNoSelection/yesNoSelection";
import { CaseType } from "../../../constants/caseType";
import Title from "../title/title";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import DateAndEditor from "../dateAndEditor";
import ResetButton from "../../common/resetButton/resetButton";

function ProductReturns() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { caseType, productReturn } = useSelector(state => state.case.case);
  const {
    productReturnRequired,
    productsHaveBeenReturned,
    productReturnToSellable,
  } = useSelector(state => state.case.localHandlingData || {});
  const {
    productReturnRequiredError,
    productsHaveBeenReturnedError,
    productReturnToSellableError,
  } = useSelector(state => state.validationErrors);

  const { localHandlingData = {} } = useSelector(state => state.case);
  const { userData } = useSelector(state => state.user);
  const { modifications = {} } = localHandlingData;
  const getModificationData = field =>
    getSpdModificationData(modifications, `${field}`);
  useEffect(() => {
    if (productReturnRequired == null) {
      dispatch(
        updateLocalHandlingDataField(
          productReturn.returnValue,
          "productReturnRequired",
          "notLocalChange"
        )
      );
    }
  }, [productReturn, productReturnRequired, dispatch]);

  const isReturningProducts =
    productReturn != null && productReturn.returnValue === true;

  const getProductReturnRequiredValue = value => {
    if (value == null) {
      return "";
    }
    return value === true ? YesNoSelectionValues.Yes : YesNoSelectionValues.No;
  };
  if (caseType === CaseType.PRODUCT_DELIVERY && isReturningProducts === false) {
    return null;
  }

  const resetProductsHaveBeenReturnedValue = () => {
    dispatch(updateValidationError({ productsHaveBeenReturnedError: "" }));
    dispatch(updateValidationError({ productReturnRequiredError: "" }));
    dispatch(
      updateLocalHandlingDataField(undefined, "productsHaveBeenReturned")
    );

    dispatch(updateLocalHandlingDataField(undefined, "productReturnRequired"));
  };

  const isResetDisabled = () =>
    productsHaveBeenReturned === undefined && productReturnRequired === true;

  const getTitle = () => {
    if (caseType === CaseType.DAMAGED_PRODUCT) {
      return (
        <Box display="flex" style={{ justifyContent: "space-between" }}>
          <Title title={t("productReturns.productReturns")} />
          <ResetButton
            onClick={resetProductsHaveBeenReturnedValue}
            disabled={isResetDisabled()}
          />
        </Box>
      );
    }
    return <Title title={t("productReturns.productReturns")} />;
  };

  return (
    <div className="handling-section">
      {getTitle()}
      {caseType === CaseType.DAMAGED_PRODUCT && (
        <>
          <Box>
            <YesNoSelection
              id="products-return-required"
              title={t("productReturns.productReturnRequired")}
              error={!!productReturnRequiredError}
              errorText={getValidationErrorMessage(
                productReturnRequiredError,
                t
              )}
              value={getProductReturnRequiredValue(productReturnRequired)}
              onValueChanged={event => {
                const value = event.target.value === YesNoSelectionValues.Yes;
                dispatch(
                  updateValidationError({ productReturnRequiredError: "" })
                );
                dispatch(
                  updateLocalHandlingDataField(value, "productReturnRequired")
                );
              }}
            />
            {getModificationData("productReturnRequired") && (
              <DateAndEditor
                modificationData={{
                  ...getModificationData("productReturnRequired"),
                  hideModifierName: true,
                }}
                user={userData}
              />
            )}
          </Box>
          <Box>
            <YesNoSelection
              id="products-have-been-returned"
              title={t("productReturns.productsHaveBeenReturned")}
              error={!!productsHaveBeenReturnedError}
              errorText={getValidationErrorMessage(
                productsHaveBeenReturnedError,
                t
              )}
              value={getProductReturnRequiredValue(productsHaveBeenReturned)}
              onValueChanged={event => {
                const value = event.target.value === YesNoSelectionValues.Yes;
                dispatch(
                  updateValidationError({ productsHaveBeenReturnedError: "" })
                );
                dispatch(
                  updateLocalHandlingDataField(
                    value,
                    "productsHaveBeenReturned"
                  )
                );
              }}
            />
            {getModificationData("productsHaveBeenReturned") && (
              <DateAndEditor
                modificationData={{
                  ...getModificationData("productsHaveBeenReturned"),
                  hideModifierName: true,
                }}
                user={userData}
              />
            )}
          </Box>
        </>
      )}
      {caseType === CaseType.PRODUCT_DELIVERY &&
        isReturningProducts === true && (
          <Box>
            <YesNoSelection
              id="products-return-sellable"
              title={t("productReturns.productsHaveBeenReturnedToStock")}
              error={!!productReturnToSellableError}
              errorText={getValidationErrorMessage(
                productReturnToSellableError,
                t
              )}
              value={getProductReturnRequiredValue(productReturnToSellable)}
              onValueChanged={event => {
                const value = event.target.value === YesNoSelectionValues.Yes;
                dispatch(
                  updateValidationError({ productReturnToSellableError: "" })
                );
                dispatch(
                  updateLocalHandlingDataField(value, "productReturnToSellable")
                );
              }}
            />
            {getModificationData("productReturnToSellable") && (
              <DateAndEditor
                modificationData={{
                  ...getModificationData("productReturnToSellable"),
                  hideModifierName: true,
                }}
                user={userData}
              />
            )}
          </Box>
        )}
    </div>
  );
}

ProductReturns.propTypes = {};

ProductReturns.defaultProps = {};

export default ProductReturns;
